<template>
  <div id="app">
    
    <router-view/>
  </div>
</template>

<script>

// rem等比适配配置文件
// 基准大小
// 设置 rem 函数
function setRem() {
  // 当前页面宽度相对于 1920宽的缩放比例，可根据自己需要修改。
  const scale = document.documentElement.clientWidth / 375;
  document.documentElement.style.fontSize =
   scale+ "px";
}
// 初始化
setRem();
// 改变窗口大小时重新设置 rem
window.onresize = function() {
  setRem();
};


</script>
<style lang="scss">

body{
  margin: 0;
  padding: 0;
  background-color: #ffffff;
}

@media screen and (max-width: 1200px) {
  body{
  margin: 0;
  padding: 0;
  background-color: #ffffff;
}
}

</style>
